// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use './design-system' as vig;
@use '@angular/material' as mat;
@use './all-themes' as app;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();

$drive-web-light-theme: vig.define-light-theme();
$drive-web-dark-theme: vig.define-dark-theme();

// Include densities and typographies
@include mat.all-component-densities($drive-web-light-theme);
@include mat.all-component-typographies(vig.$typography-config);
@include app.all-component-typographies(vig.$typography-config);

// Include light theme styles for core and each component used in your app.
.light-mode {
  @include mat.all-component-bases($drive-web-light-theme);
  @include mat.all-component-colors($drive-web-light-theme);
  @include app.all-component-colors($drive-web-light-theme);
  @include vig.color-hierarchy($drive-web-light-theme);
  @include vig.light-theme-overrides();
}

// Include dark theme styles for core and each component used in your app.
.dark-mode {
  background: #303030;
  @include mat.all-component-bases($drive-web-dark-theme);
  @include mat.all-component-colors($drive-web-dark-theme);
  @include app.all-component-colors($drive-web-dark-theme);
  @include vig.color-hierarchy($drive-web-dark-theme);
}

// Other includes
@include vig.typography-hierarchy();
@include vig.fonts('/assets/fonts');
@include vig.snackbar-classes();

// Global css
html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.mtop4 {
  margin-top: 0.25rem;
}

.mtop8 {
  margin-top: 0.5rem;
}

.mbot8 {
  margin-bottom: 0.5rem;
}

.mtop16 {
  margin-top: 1rem;
}

.mbot16 {
  margin-bottom: 1rem;
}

.mtop24 {
  margin-top: 1.5rem;
}

.mbot24 {
  margin-bottom: 1.5rem;
}

.pointer {
  cursor: pointer;
}

mat-stepper[disable-steps] .mat-step-header {
  pointer-events: none !important;
  cursor: not-allowed;
}

mat-stepper[hide-header] .mat-horizontal-stepper-header-container {
  display: none;
}

mat-stepper[hide-labels] .mat-step-label {
  display: none;
}

.intercom-lightweight-app {
  z-index: 999 !important; // make Intercom widget appear behind snackbar
}

.icon-filled {
  font-variation-settings: 'FILL' 1;
}
