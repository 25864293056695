@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Theme and palettes
  $config: mat.m2-get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $primary: map.get($config, primary);
  $accent: map.get($config, 'accent');
  $warn: map.get($config, 'warn');
  $background: map.get($config, background);


  // Common colors
  $racing-green-25: mat.m2-get-color-from-palette($primary, 25);
  $racing-green-50: mat.m2-get-color-from-palette($primary, 50);
  $racing-green-400: mat.m2-get-color-from-palette($primary, 400);
  $racing-green-500: mat.m2-get-color-from-palette($primary, 500);
  $viggo-green-700: mat.m2-get-color-from-palette($accent, 700);
  $red-700: mat.m2-get-color-from-palette($warn, 700);
  $background-background: map.get($background, 'background');
  $background-hover: map.get($background, 'hover');
  $background-app-bar: map.get($background, 'app-bar');
  $background-status-bar: map.get($background, 'status-bar');
  $foreground-hint-text: map.get($foreground, 'hint-text');
  $foreground-disabled-text: map.get($foreground, 'disabled-text');
  $foreground-divider: map.get($foreground, 'divider');

  .daily-earnings-list-container {
    --mat-table-background-color: $background-background;

    th {
      background-color: $background-background;
    }

    tr.expanded-row {
      background: $background-hover;
    }

    tr.element-row:not(.expanded-row) {
      td.expand-column {
        color: $viggo-green-700;
      }

      &:hover {
        background: $background-hover;
      }

      &:active {
        background: $background-hover;
      }
    }

    tr.detail-row {

      th,
      td {
        background: $background-app-bar;
      }

      .top-row-container {
        border-bottom-color: map.get($foreground, 'divider');
      }
    }

    tr.totals-row {
      td {
        background-color: $background-status-bar;
        color: $racing-green-500;
      }
    }

    .time {
      color: $foreground-hint-text;
    }

    .money {
      &.credit {
        color: $red-700;
      }

      &.neutral {
        color: $foreground-disabled-text;
      }

      .info-icon {
        color: $foreground-hint-text;
      }
    }
  }

  .paginator {
    background-color: $background-background;
  }

  .vertical-line {
    border-left-color: $foreground-divider;
  }
}

@mixin typography($typography-config) {
  .daily-earnings-list-container {
    td.important {
      @include mat.m2-typography-level($typography-config, subtitle-2);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}