@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Theme and palettes
  $config: mat.m2-get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $primary: map.get($config, primary);
  $accent: map.get($config, 'accent');
  $warn: map.get($config, 'warn');

  // Common colors
  $racing-green-300: mat.m2-get-color-from-palette($primary, 300);
  $viggo-green-700: mat.m2-get-color-from-palette($accent, 700);
  $red-900: mat.m2-get-color-from-palette($warn, 900);
  $foreground-hint-text: map.get($foreground, 'hint-text');
  $foreground-disabled-text: map.get($foreground, 'disabled-text');

  .daily-earnings-detail-container {
    div.circle {
      border-color: $racing-green-300;

      &.filled {
        background-color: $viggo-green-700;
        border-color: $viggo-green-700;
      }
    }

    div.vertical-line {
      border-color: $racing-green-300;
    }

    .time {
      color: $foreground-hint-text;
    }

    .money {
      &.credit {
        color: $red-900;
      }

      &.neutral,
      &.loss {
        color: $foreground-disabled-text;
      }
    }

    .unspecified {
      color: $foreground-hint-text;
    }

    // Manually override the table row separator styles because of material bug
    // that doesn't show any borders for the last row in nested tables
    tr.detail-element-row,
    tr.small-screen-row {
      td {
        border-bottom-color: map.get($foreground, 'divider');
      }
    }
  }
}

@mixin typography($typography-config) {
  .daily-earnings-detail-container {
    td.important {
      @include mat.m2-typography-level($typography-config, subtitle-2);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}