@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Theme and palettes
    $config: mat.m2-get-color-config($theme);
    $primary: map.get($config, primary);
    $accent: map.get($config, 'accent');
    $warn: map.get($config, warn);
    $background: map.get($config, background);

    // Common colors
    $grey: rgba(204, 215, 214, 0.50);
    $grey-200: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 200);
    $grey-600: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 600);
    $light-green: rgba(120, 240, 162, 0.50);
    $viggo-green-300: mat.m2-get-color-from-palette($accent, 300);
    $viggo-green-800: mat.m2-get-color-from-palette($accent, 800);
    $racing-green-300: mat.m2-get-color-from-palette($primary, 300);
    $racing-green-400: mat.m2-get-color-from-palette($primary, 400);
    $racing-green-500: mat.m2-get-color-from-palette($primary, 500);
    $background-disabled-button: mat.m2-get-color-from-palette($background, 'disabled-button');
    $background-background: mat.m2-get-color-from-palette($background, 'background');
    $warn-100: mat.m2-get-color-from-palette($warn, 100);

    .grey-black {
        background: $grey;
        color: #1C1B1F;
    }

    .grey-green {
        background: $grey;
        color: $viggo-green-800;
    }

    .green-green {
        background: $light-green;
        color: $racing-green-500;
    }

    .green-dark-green {
        background: $light-green;
        color: $racing-green-500;
    }

    .orange {
        background: rgba(255, 144, 43, 0.15);
        color: #FF902B;
    }

    .white-green {
        background: #FFF;
        color: #668784;
    }

    .red-green {
        background: rgba(249, 178, 175, 0.50);
        color: $racing-green-500;
    }

    .light-grey-dark-green {
        background: $grey-200;
        color: $racing-green-500;
    }

    .light-grey-dark-grey {
        background: $grey-200;
        color: $grey-600;
    }

    // --- Dark theme colors

    .green-light-green {
        background: $viggo-green-300;
        color: $racing-green-500;
    }

    .gray-light-green {
        background: $background-disabled-button;
        color: $racing-green-400;
    }

    .background-grey-light-green {
        background: $background-background;
        color: $racing-green-300;
    }

    .dark-orange {
        background: rgba(255, 144, 43, 0.15);
        color: #FF902B;
    }

    .red-light-green {
        background: $warn-100;
        color: $racing-green-500;
    }
}


@mixin typography($typography-config) {}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    $typography-config: mat.m2-get-typography-config($theme);

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}