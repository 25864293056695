@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Get the color config from the theme.
  $config: mat.m2-get-color-config($theme);
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);
  $accent: map.get($config, 'accent');

  // Common colors
  $grey-100: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 100);

  .settlements-container {
    --mat-table-background-color: map.get($background, 'background');

    .mat-mdc-paginator {
      background-color: map.get($background, 'background');
    }

    .mat-column-download .mat-icon {
      color: mat.m2-get-color-from-palette($accent, text) !important;
    }
  }
}

@mixin typography($typography-config) {
  .settlements-container {}
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}
