@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {

  $config: mat.m2-get-color-config($theme);
  $racing-green-500: map.get($config, 'primary', 500);
  $background: map.get($config, background);
  $background-background: map.get($background, 'background');

  .revenue-container {
    background: $background-background;
  }

  .vig-headline-4 {
    color: $racing-green-500;
  }
}

@mixin typography($typography-config) {
  .revenue-container {}
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}