// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

$light-primary-text: white;
$dark-primary-text: #003732;

@use '@angular/material' as mat;
@use 'sass:map';

$racing-green-light: (
  25: #F2F5F5,
  50: #E5EBEA,
  100: #CCD7D6,
  200: #99AFAD,
  300: #668784,
  400: #335F5B,
  500: #003732,
  600: #00302C,
  700: #022925,
  800: #042421,
  900: #041F1C,
  A100: #5CE5DA,
  A200: #41D9CC,
  A400: #26BFB2,
  A700: #0F998E,
  contrast: (25: $dark-primary-text,
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text )
);

$racing-green-dark: (
  50: #003732,
  100: #335F5B,
  200: #668784,
  300: #99AFAD,
  400: #CCD7D6,
  500: #E5EBEA,
  600: #F2F5F5,
  700: #FFFFFF,
  800: #FFFFFF,
  900: #FFFFFF,
  A100: #5CE5DA,
  A200: #41D9CC,
  A400: #26BFB2,
  A700: #0F998E,
  contrast: (50: $light-primary-text,
    100: $light-primary-text,
    200: $light-primary-text,
    300: $light-primary-text,
    400: $dark-primary-text,
    500: $dark-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text )
);

$viggo-green-light: (
  25: #F4FEF7,
  50: #E8FDEF,
  100: #D2FAE0,
  200: #A5F5C1,
  300: #78F0A2,
  400: #4BEB83,
  500: #1EE664,
  600: #1BD95D,
  700: #18BF52,
  800: #15A647,
  900: #0E8C3A,
  A100: #73FFA4,
  A200: #40FF83,
  A400: #24ED6A,
  A700: #00E550,
  contrast: (25: $dark-primary-text,
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text )
);

$viggo-green-dark: (
  50: #0F7332,
  100: #0E8C3A,
  200: #15A647,
  300: #18BF52,
  400: #1BD95D,
  500: #1EE664,
  600: #4BEB83,
  700: #78F0A2,
  800: #A5F5C1,
  900: #D2FAE0,
  A100: #73FFA4,
  A200: #40FF83,
  A400: #24ED6A,
  A700: #00E550,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $dark-primary-text,
    700: $dark-primary-text,
    800: $dark-primary-text,
    900: $dark-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $dark-primary-text )
);

$warn-light: (
  50: #FEF5F5,
  100: #FDE2E1,
  200: #F9B2AF,
  300: #F5827C,
  400: #F15B54,
  500: #EF3E36,
  600: #E33B33,
  700: #D73831,
  800: #BF322B,
  900: #B32F28,
  contrast: (50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white)
);

$warn-dark: (
  50: #9B2823,
  100: #B32F28,
  200: #BF322B,
  300: #D73831,
  400: #E33B33,
  500: #EF3E36,
  600: #F15B54,
  700: #F5827C,
  800: #F9B2AF,
  900: #FDE2E1,
  contrast: (50: white,
    100: white,
    200: white,
    300: white,
    400: white,
    500: white,
    600: black,
    700: black,
    800: black,
    900: black)
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary-palette-light: mat.m2-define-palette($racing-green-light);
$primary-palette-dark: mat.m2-define-palette($racing-green-dark);
$accent-palette-light: mat.m2-define-palette($viggo-green-light, 700);
$accent-palette-dark: mat.m2-define-palette($viggo-green-dark, 700);
$warn-palette-light: mat.m2-define-palette($warn-light);
$warn-palette-dark: mat.m2-define-palette($warn-dark);

// Typography config
$typography-config: mat.m2-define-typography-config($headline-1: mat.m2-define-typography-level(6rem, 6rem, 700, Gellix),
    $headline-2: mat.m2-define-typography-level(3.75rem, 3.75rem, 700, Gellix),
    $headline-3: mat.m2-define-typography-level(3rem, 3.125rem, 700, Gellix),
    $headline-4: mat.m2-define-typography-level(2rem, 2.5rem, 700, Gellix),
    $headline-5: mat.m2-define-typography-level(1.5rem, 2rem, 600, Gellix),
    $headline-6: mat.m2-define-typography-level(1.25rem, 2rem, 600, Gellix),
    $subtitle-1: mat.m2-define-typography-level(1rem, 1.5rem, 600, Gellix),
    $subtitle-2: mat.m2-define-typography-level(0.875rem, 1.25rem, 600, Gellix),
    $body-1: mat.m2-define-typography-level(1rem, 1.5rem, 400, Gellix),
    $body-2: mat.m2-define-typography-level(0.875rem, 1.25rem, 400, Gellix),
    $caption: mat.m2-define-typography-level(0.875rem, 1.25rem, 400, Gellix),
    $button: mat.m2-define-typography-level(0.875rem, 2.25rem, 600, Gellix),
    $overline: null,
  );

/// Creates a container object for a light theme to be given to individual component theme mixins.
/// @returns {Map} A complete Angular Material theme map.
@function define-light-theme() {
  $theme: mat.m2-define-light-theme((color: (primary: $primary-palette-light,
          accent: $accent-palette-light,
          warn: $warn-palette-light,
        ),
        typography: $typography-config ));

  // Get the color config so we can override it
  $config: mat.m2-get-color-config($theme);
  $foreground-palette: map.get($config, 'foreground');
  $background-palette: map.get($config, 'background');

  // Angular material overrides
  $foreground-palette: map.merge($foreground-palette, ('text': $dark-primary-text ));

  // Merge
  $config: map.merge($config, ('background': $background-palette,
        foreground: $foreground-palette ));
  $theme: map.set($theme, color, $config);

  @return $theme;
}

/// Creates a container object for a dark theme to be given to individual component theme mixins.
/// @returns {Map} A complete Angular Material theme map.
@function define-dark-theme() {
  $theme: mat.m2-define-dark-theme((color: (primary: $primary-palette-dark,
          accent: $accent-palette-dark,
          warn: $warn-palette-dark,
        ),
        typography: $typography-config ));

  // Get the color config so we can override it
  $config: mat.m2-get-color-config($theme);
  $foreground-palette: map.get($config, 'foreground');
  $background-palette: map.get($config, 'background');

  // Merge
  $config: map.merge($config, ('background': $background-palette,
        foreground: $foreground-palette ));
  $theme: map.set($theme, color, $config);

  @return $theme;
}

/// Font setup
/// @param {String} $fonts-dir The base directory for all fonts
@mixin fonts($fonts-dir) {
  @font-face {
    font-family: 'Gellix';
    src:
      url($fonts-dir + '/Gellix-Regular.woff2') format("woff2"),
      url($fonts-dir + '/Gellix-Regular.woff') format("woff"),
      url($fonts-dir + '/Gellix-Regular.eot') format("eot");
    font-weight: 400;
  }

  @font-face {
    font-family: 'Gellix';
    src:
      url($fonts-dir + '/Gellix-Medium.woff2') format("woff2"),
      url($fonts-dir + '/Gellix-Medium.woff') format("woff"),
      url($fonts-dir + '/Gellix-Medium.eot') format("eot");
    font-weight: 500;
  }

  @font-face {
    font-family: 'Gellix';
    src:
      url($fonts-dir + '/Gellix-SemiBold.woff2') format("woff2"),
      url($fonts-dir + '/Gellix-SemiBold.woff') format("woff"),
      url($fonts-dir + '/Gellix-SemiBold.eot') format("eot");
    font-weight: 600;
  }

  @font-face {
    font-family: 'Gellix';
    src:
      url($fonts-dir + '/Gellix-Bold.woff2') format("woff2"),
      url($fonts-dir + '/Gellix-Bold.woff') format("woff"),
      url($fonts-dir + '/Gellix-Bold.eot') format("eot");
    font-weight: 700;
  }

  body {
    font-family: Gellix, sans-serif;
  }
}

/// Emits baseline color styles. Use these classes for common colors.
@mixin color-hierarchy($theme) {
  $config: mat.m2-get-color-config($theme);
  $background: map.get($config, 'background');
  $foreground: map.get($config, 'foreground');
  $accent: map.get($config, 'accent');

  .vig-color-background {
    background: mat.m2-get-color-from-palette($background, 'background');
  }

  .vig-color-foreground-text {
    color: mat.m2-get-color-from-palette($foreground, 'text');
  }

  .vig-color-accent-text {
    color: mat.m2-get-color-from-palette($accent, 'text');
  }

  .vig-color-grey {
    color: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 700);
  }
}

/// Emits baseline typographic styles.
@mixin typography-hierarchy() {
  $config: $typography-config;

  .vig-headline-1 {
    @include mat.m2-typography-level($config, headline-1);
  }

  .vig-headline-2 {
    @include mat.m2-typography-level($config, headline-2);
  }

  .vig-headline-3 {
    @include mat.m2-typography-level($config, headline-3);
  }

  .vig-headline-4 {
    @include mat.m2-typography-level($config, headline-4);
  }

  .vig-h1,
  .vig-headline-5,
  h1 {
    @include mat.m2-typography-level($config, headline-5);
  }

  .vig-h2,
  .vig-headline-6,
  h2 {
    @include mat.m2-typography-level($config, headline-6);
  }

  .vig-h3,
  .vig-subtitle-1,
  h3 {
    @include mat.m2-typography-level($config, subtitle-1);
  }

  .vig-h4,
  .vig-subtitle-2,
  h4 {
    @include mat.m2-typography-level($config, subtitle-2);
  }

  .vig-body-1 {
    @include mat.m2-typography-level($config, body-1);
  }

  .vig-body-2 {
    @include mat.m2-typography-level($config, body-2);
  }

  .vig-caption {
    @include mat.m2-typography-level($config, caption);
  }
}


// Snackbar classes: 'success', 'error'
@mixin snackbar-classes() {
  .success.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      // Background color of entire snackbar:
      background-color: mat.m2-get-color-from-palette($racing-green-light, 500);

      .mdc-snackbar__label {
        // Color of snackbar text
        color: #ffffff;
      }

      button {
        // Color of snackbar button text
        color: #ffffff !important;
      }
    }
  }

  .error.mat-mdc-snack-bar-container {
    .mdc-snackbar__surface {
      background-color: mat.m2-get-color-from-palette($warn-light, 700);

      .mdc-snackbar__label {
        color: #ffffff;
      }

      button {
        color: #ffffff !important;
      }
    }
  }
}

/// Angular material hardcodes some values to be used by MDC in _mdc-helpers.scss, @mixin using-mdc-theme().
/// Until that's fixed and there is a way to override using variables, use this.
@mixin light-theme-overrides() {
  .mat-mdc-header-cell {
    color: $dark-primary-text;
  }

  .mdc-data-table__cell {
    color: $dark-primary-text;
  }
}