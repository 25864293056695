@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Theme and palettes
  $config: mat.m2-get-color-config($theme);
  $foreground: map.get($config, foreground);

  // Common colors
  $grey-800: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 800);

  .empty-table-container {
    .message {
      color: $grey-800;
    }
  }
}

@mixin typography($typography-config) {
  .empty-table-container {

  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}
