@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Theme and palettes
    $config: mat.m2-get-color-config($theme);
    $primary: map.get($config, primary);

    // Common colors
    $grey-700: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 700);
    $racing-green-500: mat.m2-get-color-from-palette($primary, 500);

    .detail-item-list {
        .details-list-item {
            .name {
                color: $racing-green-500;
            }

            .value {
                color: $racing-green-500;
            }
        }
    }
}

@mixin typography($typography-config) {}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    $typography-config: mat.m2-get-typography-config($theme);

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}