@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  // Theme and palettes
  $config: mat.m2-get-color-config($theme);
  $primary: map.get($config, primary);
  $accent: map.get($config, 'accent');
  $warn: map.get($config, 'warn');
  $background: map.get($config, background);
  $foreground: map.get($config, foreground);

  // Common colors
  $grey-100: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 100);
  $grey-700: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 700);
  $racing-green-25: mat.m2-get-color-from-palette($primary, 25);
  $racing-green-50: mat.m2-get-color-from-palette($primary, 50);
  $racing-green-400: mat.m2-get-color-from-palette($primary, 400);
  $racing-green-500: mat.m2-get-color-from-palette($primary, 500);
  $viggo-green-700: mat.m2-get-color-from-palette($accent, 700);

  $background-background: map.get($background, 'background');
  $background-hover: map.get($background, 'hover');
  $background-app-bar: map.get($background, 'app-bar');
  $background-status-bar: map.get($background, 'status-bar');

  $foreground-hint-text: map.get($foreground, 'hint-text');

  .shift-active {
    color: $viggo-green-700;
  }

  .shift-list-container {
    --mat-table-background-color: $background-background;

    th {
      background-color: $background-background;
    }

    tr.totals-row {
      td {
        background-color: $background-status-bar;
        color: $racing-green-500;
      }
    }

    .time {
      color: $foreground-hint-text;
    }

    tr.expanded,
    tr.main-row:hover {
      background: $background-hover !important;
    }

    .mat-column-expandedDetail {
      background-color: $racing-green-25;
    }

    .mat-column-expand:not(.expanded) {
      color: $viggo-green-700;
    }
  }

  .mobile {
    .revenue {
      color: $racing-green-500;
    }

    .detail-row {
      background: $background-app-bar;
    }
  }

  .paginator {
    background-color: $background-background;
  }
}

@mixin typography($typography-config) {
  .shift-list-container {
    td.important {
      @include mat.m2-typography-level($typography-config, subtitle-2);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}