@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Theme and palettes
    $config: mat.m2-get-color-config($theme);
    $primary: map.get($config, primary);
    $background: map.get($config, background);
    $foreground: map.get($config, foreground);

    // Common colors
    $grey-700: mat.m2-get-color-from-palette(mat.$m2-grey-palette, 700);
    $racing-green-50: mat.m2-get-color-from-palette($primary, 50);
    $racing-green-500: mat.m2-get-color-from-palette($primary, 500);
    $background-hover: map.get($background, 'hover');
    $foreground-hint-text: map.get($foreground, 'hint-text');

    .tile {
        background: $background-hover;

        .title {
            color: $foreground-hint-text;
        }

        .value {
            color: $racing-green-500;
        }
    }
}

@mixin typography($typography-config) {}

@mixin theme($theme) {
    $color-config: mat.m2-get-color-config($theme);

    @if $color-config !=null {
        @include color($theme);
    }

    $typography-config: mat.m2-get-typography-config($theme);

    @if $typography-config !=null {
        @include typography($typography-config);
    }
}