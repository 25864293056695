@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $config: mat.m2-get-color-config($theme);
  $accent: map.get($config, accent);
  $viggo-green-700: mat.m2-get-color-from-palette($accent, 700);
  $background: map.get($config, background);

  $background-background: map.get($background, background);
  $background-hover: map.get($background, 'hover');

  .navbar-container {
    .active-link {
      background: $background-hover;

      .mat-icon {
        color: $viggo-green-700;
      }
    }

    .mat-expansion-panel {
      background: $background-background;
    }
  }
}

@mixin typography($typography-config) {
  .navbar-container {
    .active-link .mdc-list-item__primary-text {
      @include mat.m2-typography-level($typography-config, subtitle-1);
    }
  }
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config !=null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config !=null {
    @include typography($typography-config);
  }
}